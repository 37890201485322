.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-brand {
  width: 130px;
  font-size: 24px;
}
.navbar-brand img {
  /* put value of image height as your need */
  float: left;
  margin-right: 7px;
}

.poly-brand {
  margin-right: 0rem !important;
  margin-left: 1rem !important;
}

.poly-brand img {
  float: right !important;
  margin-right: 0px !important;
  margin-left: 7px !important;
}

.features-area .single-card {
  background-color: white !important;
}

.contact-area input,
textarea {
  background-color: transparent !important;
}
/*--------- FILTER 1 ----------------*/

#msform {
  text-align: center;
  position: relative;
  margin-top: 20px;
}

.shadow {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 16%) !important;
}

.radius8 {
  border-radius: 8px !important;
}
#msform fieldset .form-card {
  background: white;
  border: 0 none;
  border-radius: 8px;
  padding: 20px 40px 30px 40px;
  box-sizing: border-box;
  position: relative;
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative;
}

#msform fieldset:not(:first-of-type) {
  display: none;
}

#msform fieldset .form-card {
  text-align: left;
  color: #9e9e9e;
}

#msform input,
#msform textarea {
  padding: 0px 8px 4px 8px;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  font-family: montserrat;
  color: #2c3e50;
  font-size: 16px;
  letter-spacing: 1px;
}

#msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none;
  font-weight: bold;
  border-bottom: 2px solid skyblue;
  outline-width: 0;
}

#msform .action-button {
  font-family: "LineIcons" !important;
  font-size: 2rem;
  width: 6rem;
  height: 3rem;
  background: skyblue;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
  border-radius: 2rem;
}

#msform .action-button:hover,
#msform .action-button:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px skyblue;
}

#msform .action-button-previous {
  width: 100px;
  background: #616161;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #616161;
}

select.list-dt {
  border: none;
  outline: 0;
  border-bottom: 1px solid #ccc;
  padding: 2px 5px 3px 5px;
  margin: 2px;
}

select.list-dt:focus {
  border-bottom: 2px solid skyblue;
}

.card {
  z-index: 0;
  border: none;
  border-radius: 0.5rem;
  position: relative;
}

.fs-title {
  font-size: 25px;
  color: #2c3e50;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
}

#progressbar {
  overflow: hidden;
  color: lightgrey;
}

#progressbar .active {
  color: #000000;
}

#progressbar li {
  list-style-type: none;
  font-size: 12px;
  width: 20%;
  float: left;
  position: relative;
}

#progressbar #progressSteppersonal:before {
  font-family: FontAwesome;
  content: "1";
}

#progressbar #progressStepsize:before {
  font-family: FontAwesome;
  content: "2";
}

#progressbar #progressStepplatform:before {
  font-family: FontAwesome;
  content: "3";
}

#progressbar #progressStepmonitors:before {
  font-family: FontAwesome;
  content: "4";
}
#progressbar #progressStepautofocus:before {
  font-family: FontAwesome;
  content: "5";
}

#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 18px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}

#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: skyblue;
}

.radio-group {
  position: relative;
  margin-bottom: 25px;
}

.radio {
  display: inline-block;
  width: 204;
  height: 104;
  border-radius: 0;
  background: lightblue;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  cursor: pointer;
  margin: 8px 2px;
}

.radio:hover {
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.radio.selected {
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
}

.fit-image {
  width: 100%;
  object-fit: cover;
}

.navbar-area.navbar-eight {
  background-color: white !important;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.rounded-buttons .rounded-three {
  background-color: tomato !important;
  background-image: none !important;
}

.slider-five .carousel .carousel-control-prev,
.slider-five .carousel .carousel-control-next {
  color: white !important;
}

.slider-five .carousel .carousel-indicators li {
  background-color: white !important;
}

.slider-five .carousel .carousel-control-prev,
.slider-five .carousel .carousel-control-next {
  text-decoration: none !important;
  font-size: 48px !important;
}
/*------------ PRODUCTS -----------*/
.text-gray {
  color: #aaa;
}

#sidebar .carousel .carousel-control-prev,
#sidebar .carousel .carousel-control-next {
  color: darkgray !important;
}

img {
  /*height: 170px;*/
  /*width: 140px;*/
}

/*----------- FILTER 2 with products 2 ----------*/
#burgundy {
  color: rgb(153, 40, 59);
}

#orange,
select,
.btn {
  color: skyblue;
}

.fa-user-o,
.fa-shopping-cart {
  font-size: 20px;
  padding: 4px;
}

.form-group {
  margin-bottom: 5px;
}

label {
  padding-left: 10px;
}

.form-group:last-child {
  margin-bottom: 0;
}

h6 {
  margin-bottom: 0px;
}

#sort {
  outline: none;
  border: none;
}

@media (min-width: 1200px) {
  #sidebar {
    padding: 20px;
    float: left;
  }

  .filter,
  #mobile-filter {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  #sidebar {
    padding: 20px;
    float: left;
  }

  .filter,
  #mobile-filter {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #sidebar {
    display: none;
    padding: 20px;
    float: left;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  #sidebar {
    display: none;
    padding: 20px;
    float: left;
  }

  #off {
    padding-left: 2px;
  }

  #sorting span,
  #res {
    font-size: 14px;
  }
}

@media (max-width: 575px) {
  #sidebar {
    display: none;
  }

  .filter {
    margin-left: 70%;
    margin-top: 2%;
  }

  #sorting,
  #res {
    font-size: 12px;
    margin-top: 2%;
  }
}

/*-----------button toggle---------*/
.btn-toggle {
  margin: 0 4rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
  color: #6b7381;
  background: #bdc1c8;
}
.btn-toggle:focus,
.btn-toggle:focus.active,
.btn-toggle.focus,
.btn-toggle.focus.active {
  outline: none;
}
.btn-toggle:before,
.btn-toggle:after {
  line-height: 1.5rem;
  width: 4rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity 0.25s;
}
.btn-toggle:before {
  content: "Off";
  left: -4rem;
}
.btn-toggle:after {
  content: "On";
  right: -4rem;
  opacity: 0.5;
}
.btn-toggle > .handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left 0.25s;
}
.btn-toggle.active {
  transition: background-color 0.25s;
}
.btn-toggle.active {
  background-color: #29b5a8;
}
.btn-toggle.active > .handle {
  left: 1.6875rem;
  transition: left 0.25s;
}
.btn-toggle.active:before {
  opacity: 0.5;
}
.btn-toggle.active:after {
  opacity: 1;
}

.switch {
  border-radius: 2rem !important;
}

.switch-on {
  background-color: skyblue;
  border-color: skyblue;
  border-radius: 2rem !important;
}
.switch-off {
  background-color: lightgray;
  border-color: lightgray;
  border-radius: 2rem !important;
}

.on .switch-handle {
  height: 2rem;
  width: 2rem;
  transform: translateX(-1.1rem);
  padding: 0px !important;
  border-radius: 2rem;
  top: 0.1rem;
}
.off .switch-handle {
  height: 2rem;
  width: 2rem;
  transform: translateX(1.1rem);
  padding: 0px !important;
  border-radius: 2rem;
  top: 0.1rem;
}

#msform .btn-primary {
  background-color: skyblue !important;
  border-color: skyblue !important;
}
